<!-- 分享视频 -->
<template>
  <div class="wrap" v-loading="loading">
    <div class="play-margin" v-for="(item, index) in videoList" :key="index">
      <video
        width="190"
        controls
        :poster="item.file_first"
        :id="`play${index}`"
        @contextmenu.prevent="youjian()"
      >
        <source type="video/mp4" :src="item.video_url" />
      </video>

      <button class="downLook" @click="down(item, index)">生成水印</button>
    </div>
    <!-- <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
    </div>-->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getImg, getwatermark } from '@/api/promotion'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      videoList: [],
      hint: '生成水印',

      lastTime: 10,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //海报禁止右键
    youjian() {
      return false
    },
    down(item, index) {
      if (this.lastTime != 0 && this.lastTime != 10) return
      console.log(item)
      let name = sessionStorage.getItem('name')
      let place = sessionStorage.getItem('place')
      getwatermark({
        video_url: item.file_id,
        video_id: item.id,
        text1: place || '请先填写公司信息',
        text2: name || 'xxx',
        text3: '我们欢迎你',
      }).then((res) => {
        if (res.code === 0) {
          let player = document.getElementById(`play${index}`)
          player.src = res.data.file_url

          player.play()
          this.$message({
            message: res.msg,
            type: 'success',
          })
        } else {
          this.lastTime = 10
          let msg = res.msg + '请再次点击生成水印'
          let a = this.$message({
            showClose: true,
            duration: '10000',
            message: msg + `(${this.lastTime}s)`,
            type: 'success',
          })
          let b = setInterval(() => {
            // console.log('进来了吗', this.lastTime)
            if (this.lastTime === 0) {
              clearInterval(b)
              b = null
              return
            } else {
              this.lastTime--
              a.message = msg + `(${this.lastTime}s)`
            }
          }, 1000)
        }
        // console.log(res)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    getImg({ show_data: 'video' }).then((res) => {
      console.log(res)
      this.loading = false
      this.videoList = res.data.video_data
    })
    // this.$nextTick(() => {
    //   // console.log(this.$refs['play0'][0])
    //   var canvas = this.$refs.canvas
    //   console.log(canvas)
    //   canvas.width = 190
    //   canvas.height = 340
    //   var ctx = canvas.getContext('2d')
    //   // var img = new Image()
    //   // img.src = this.$refs['play0'][0].poster
    //   // img.onload = () => {
    //   //   console.log(ctx.fillText)
    //   //   ctx.drawImage(img, 0, 0, 190, 340)
    //   //   ctx.font = ' 50px'
    //   //   ctx.fillStyle = 'red'
    //   //   ctx.fillText('快手', 10, 46) //公司名称
    //   // }
    //   // var video = new Video()
    //   // video.src = 'https://image.hr24.com.cn/group1/M00/00/0B/video2.mp4'
    //   // this.$refs['play0'][0].addElementListener('play', () => {
    //   //   ctx.drawImage(this.$refs['play0'][0].firstChild, 0, 0, 190, 340)
    //   // })
    //   this.$refs.play.addEventListener('play', () => {
    //     //画布上画视频，需要动态地获取它，一帧一帧地画出来
    //     setInterval(() => {
    //       console.log(11)
    //       ctx.drawImage(this.$refs.play, 0, 0, 190, 340)
    //       ctx.font = ' 50px'
    //       ctx.fillStyle = 'red'
    //       ctx.fillText('快手', 50, 50) //公司名称
    //     }, 16)
    //   })
    // })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  // margin-top: 80px;
  min-height: 80vh;
}
.play-margin {
  margin-right: 30px;
  margin-top: 20px;
  display: inline-block;
  width: 190px;
  height: 340px;
}
.pagination {
  width: 50%;
  margin: 20px auto 0;
}
.downLook {
  width: 190px;
  text-align: center;
  background-color: #ff9900;
  color: #fff;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
